<template>
    <v-container fluid>
        <!-- v-row class="text-center">
            <v-col cols="12">
                <h1>JSF</h1>
            </v-col>
        </v-row -->
        <v-row v-if="$route.path === '/'" class="text-center">
            <keep-alive>
                <JsfList />
            </keep-alive>
        </v-row>
        <v-row v-if="$route.path === '/edit/jsf/' + $route.params.id" class="text-center">
            <JsfEdit />
        </v-row>
        <!-- v-row v-if="$route.path === '/print/jsf/' + $route.params.id" class="text-center">
            <JsfEdit />
        </v-row -->
        <v-row v-if="$route.path === '/new/jsf'" class="text-center">
            <JsfEdit />
        </v-row>
    </v-container>
</template>

<script>
import JsfEdit from '@/components/JsfForm.vue'
import JsfList from '@/components/JsfList.vue'

export default {
    name: 'Jsf',
    components: {
        JsfEdit,
        JsfList,
    },
}
</script>